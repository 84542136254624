import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles'
import typography from './typography'
import palette from './palette'
import componentsOverride from './overrides'

ThemeConfig.propTypes = {
  children: PropTypes.node
}
export default function ThemeConfig({ children }) {
  const themeOptions = useMemo(
    () => ({
      typography,
      palette
    }),
    []
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
