import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper';
import { CardMedia, Grid, Stack, Typography } from '@mui/material'
import { Icon } from '@iconify/react';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import './style.css'
// import required modules
import { Autoplay } from "swiper";
import Styled from 'styled-components'

const SliderContainer = Styled('div')({
  marginTop: "40px",
  '@media(min-width:320px) and (max-width:900px)': {
    display: "none"
  }
})
const MobileContent = Styled(Grid)({
  display: 'none',
  '@media(min-width:320px) and (max-width:900px)': {
    display: "block"
  }
})
const LoadMoreButton = Styled(Stack)({
  borderRadius: '100px',
  padding: "5px 30px 5px 5px",
  width: "200px",
  color: '#ffff',
  background: "#96611B",
  cursor: "pointer"
})

export default function Slider() {
  const [loadMore, setLoadMore] = useState(false)
  const navigationNextRef = useRef(null)
  const loadMoreHandler = () => {
    setLoadMore(true)
  }
  return (
    <>
      <SliderContainer>
        <Swiper
          slidesPerView={1.5}
          spaceBetween={30}
          loop={true}
          pagination={false}
          centeredSlides={true}
          lazyLoading={true}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: true,
          // }}
          grabCursor={true}
          navigation={{
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <CardMedia
              component="img"
              image="./assets/images/YaeOrganics.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={2}>
              <Typography className="slideText " sx={{ color: "rgba(242, 183, 3, 1)", fontSize: "1.5rem", fontWeight: 600 }}>Yae <span style={{ color: 'rgba(132, 229, 85, 1)', fontWeight: 300, marginLeft: '-4px' }}>Organics</span></Typography>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <CardMedia
              component="img"
              image="./assets/images/baby.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={2}>
              <Typography className="slideText " sx={{ color: "rgba(96, 195, 200, 1)", fontSize: "1.5rem", fontWeight: 600 }}>Baby <span style={{ color: 'rgba(254, 234, 197, 1)', fontWeight: 300, marginLeft: '-4px' }}>Seaters</span></Typography>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <CardMedia
              component="img"
              image="./assets/images/Masingo.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={2}>
              <Typography className="slideText " sx={{ color: "rgba(148, 220, 161, 1)", fontSize: "1.5rem", fontWeight: 600 }}>Ma <span style={{ color: 'rgba(255, 255, 255, 1)', fontWeight: 300, marginLeft: '-4px' }}>singo</span></Typography>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <CardMedia
              component="img"
              image="./assets/images/wididi.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={2}>
              <Typography className="slideText " sx={{ color: "rgba(255, 100, 102, 1)", fontSize: "1.5rem", fontWeight: 600 }}>Wi <span style={{ color: 'rgba(255, 255, 255, 1)', fontWeight: 300, marginLeft: '-4px' }}>didi</span></Typography>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <CardMedia
              component="img"
              image="./assets/images/dylanDavids.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={2}>
              <Typography className="slideText " sx={{ color: "rgba(183, 183, 183, 1)", fontSize: "1.5rem" }}>Dylan <span style={{ color: 'rgba(255, 255, 255, 1)', marginLeft: '-1px' }}>& Davids</span></Typography>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <CardMedia
              component="img"
              image="./assets/images/areolite.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={2}>
              <Typography className="slideText " sx={{ color: "rgba(244, 153, 32, 1)", fontSize: "1.5rem" }}>Areo <span style={{ color: 'rgba(249, 249, 249, 1)', marginLeft: '-4px' }}>Lite</span></Typography>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <CardMedia
              component="img"
              image="./assets/images/neodirect.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={2}>
              <Typography className="slideText " sx={{ color: "rgba(205, 70, 162, 1)", fontSize: "1.5rem" }}>Neo <span style={{ color: 'rgba(239, 239, 239, 1)', marginLeft: '-4px' }}>Direct</span></Typography>
            </Stack>
          </SwiperSlide>
          <Stack ref={navigationNextRef} direction='row' justifyContent='center' alignItems='center' sx={{ position: 'absolute', bottom: 40, right: 300, zIndex: 1000, cursor: "pointer" }}>
            <CardMedia
              component='img'
              image='./assets/images/sliderArrow.svg'
              sx={{ width: '50px', }}
            />
          </Stack>
        </Swiper>
      </SliderContainer>
      {/* mobile Container */}


      <MobileContent mt={6} container justifyContent='center' alignItems='center' sx={{ padding: "0px 30px" }}>
        <Grid item xs={12} sm={12}>
          <Stack>
            <CardMedia
              component="img"
              image="./assets/images/YaeOrganics.jpg"
              alt="arrow Img"
              sx={{ width: '100%', height: "100%" }}
            />
            <Stack mt={1} mb={4}>
              <Typography className="slideText " sx={{ color: "rgba(242, 183, 3, 1)", fontSize: "1.5rem", fontWeight: 600 }}>Yae <span style={{ color: 'rgba(132, 229, 85, 1)', fontWeight: 300, marginLeft: '-4px' }}>Organics</span></Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CardMedia
            component="img"
            image="./assets/images/baby.svg"
            alt="arrow Img"
            sx={{ width: '100%', height: "80%" }}
          />
          <Stack mt={1} mb={4}>
            <Typography className="slideText " sx={{ color: "rgba(96, 195, 200, 1)", fontSize: "1.5rem", fontWeight: 600 }}>Baby <span style={{ color: 'rgba(254, 234, 197, 1)', fontWeight: 300, marginLeft: '-4px' }}>Seaters</span></Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CardMedia
            component="img"
            image="./assets/images/Masingo.jpg"
            alt="arrow Img"
            sx={{ width: '100%', height: "80%" }}
          />
          <Stack mt={1} mb={4}>
            <Typography className="slideText " sx={{ color: "rgba(148, 220, 161, 1)", fontSize: "1.5rem", fontWeight: 600 }}>Ma <span style={{ color: 'rgba(255, 255, 255, 1)', fontWeight: 300, marginLeft: '-4px' }}>singo</span></Typography>
          </Stack>
        </Grid>
        {loadMore && <>
          <Grid item xs={12} sm={12}>
            <CardMedia
              component="img"
              image="./assets/images/wididi.jpg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={1} mb={4}>
              <Typography className="slideText " sx={{ color: "rgba(255, 100, 102, 1)", fontSize: "1.5rem", fontWeight: 600 }}>Wi <span style={{ color: 'rgba(255, 255, 255, 1)', fontWeight: 300, marginLeft: '-4px' }}>didi</span></Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CardMedia
              component="img"
              image="./assets/images/dylanDavids.jpg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={1} mb={4}>
              <Typography className="slideText " sx={{ color: "rgba(183, 183, 183, 1)", fontSize: "1.5rem" }}>Dylan <span style={{ color: 'rgba(255, 255, 255, 1)', marginLeft: '-1px' }}>& Davids</span></Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CardMedia
              component="img"
              image="./assets/images/areolite.jpg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={1} mb={4}>
              <Typography className="slideText " sx={{ color: "rgba(244, 153, 32, 1)", fontSize: "1.5rem" }}>Areo <span style={{ color: 'rgba(249, 249, 249, 1)', marginLeft: '-4px' }}>Lite</span></Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CardMedia
              component="img"
              image="./assets/images/neodirect.jpg"
              alt="arrow Img"
              sx={{ width: '100%', height: "80%" }}
            />
            <Stack mt={1} mb={4}>
              <Typography className="slideText " sx={{ color: "rgba(205, 70, 162, 1)", fontSize: "1.5rem" }}>Neo <span style={{ color: 'rgba(239, 239, 239, 1)', marginLeft: '-4px' }}>Direct</span></Typography>
            </Stack>
          </Grid>
        </>}
      </MobileContent>
      {!loadMore && <MobileContent>
        <Stack mt={3} justifyContent='center' alignItems='center'>
          <LoadMoreButton onClick={loadMoreHandler} direction='row' justifyContent='space-between' alignItems='center'>
            <div style={{ background: 'rgba(0,0,0,0.2)', width: '43px', height: "43px", borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
              <Icon icon="material-symbols:add" color="#fff" width="25px" height="25px" />
            </div>
            <Typography sx={{ color: "#FFFFFF", fontWeight: '600' }}>Load More</Typography>
          </LoadMoreButton>
        </Stack>
      </MobileContent>}
    </>
  );
}
