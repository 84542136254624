import React from 'react'
import { CardMedia, Container, Stack, Typography } from '@mui/material'

const Banner = () => {
  return (
    <Container maxWidth='md' sx={{ paddingTop: "60px" }}>
      <Stack justifyContent='center' alignItems='center' sx={{ '@media(min-width:320px) and (max-width:900px)': { paddingLeft: '0px !important', paddingRight: '0px !important' }, padding: "0px 90px" }}>
        <Typography variant='subtitle' sx={{ marginLeft: "60px", fontSize: "2rem", color: "#E5E5E5", fontWeight: 600 }}>WE <span style={{ color: '#F49920' }}>SHOULD</span></Typography>
        <Stack direction='row'>
          <Typography variant='h1' sx={{ color: "#FFFFFF", fontWeight: 700, fontSize: "6rem" }}>Ch</Typography>
          <CardMedia
            component='img'
            image='./assets/images/chat.svg'
            sx={{ objectFit: "fill" }}
          />
          <Typography variant='h1' sx={{ color: "#FFFFFF", fontWeight: 700, fontSize: "6rem" }}>t</Typography>
        </Stack>
        <Stack mt={2} spacing={3}>
          <Typography variant='body1' sx={{ fontSize: "1.2rem", textAlign: "center", color: "#FFFFFF", fontWeight: 200 }}>If you’re ready to grow your business, we’d love to help you do it. Get in touch and we will respond ASAP!</Typography>
          <Typography sx={{ fontSize: "1.2rem", textAlign: "center", color: "#FFFFFF", fontWeight: 300 }}>info@advanceamz.com</Typography>
        </Stack>
      </Stack>
    </Container>
  )
}

export default Banner