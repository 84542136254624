import React from 'react'
import Styled from 'styled-components'
import { Box, Button, Container, Stack } from '@mui/material'
import { Outlet, NavLink } from 'react-router-dom'
import CardMedia from '@mui/material/CardMedia'
import { Link as RouterLink } from 'react-router-dom'

// import { HashLink as Link } from 'react-router-hash-link'
import { Icon } from '@iconify/react';
import './nav.css'
import Footer from './footer'
const Navbar = () => {
  const NavWrapper = Styled(Container)({
    '@media(min-width:320px) and (max-width:900px)': {
      width: '100% !important'
      // height: '85px'
    }
  })
  const NavLinksWrapper = Styled(Container)({
    '@media(min-width:320px) and (max-width:900px)': {
      width: '100% !important'
      // height: '85px'
    }
  })
  const BarsIcon = Styled(Icon)({
    display: 'none',
    '@media(min-width:320px) and (max-width:900px)': {
      height: '100%',
      width: '40px',
      display: 'block',
      position: 'absolute',
      left: '20px',
      top: -7,
      color: '#fff',
      // height: '85px'
    }
  })
  const MessageIcon = Styled(Box)({
    display: 'none',
    '@media(min-width:320px) and (max-width:900px)': {
      height: '40px',
      width: '40px',
      display: 'block',
      position: 'absolute',
      right: '20px',
      top: 45,
      borderRadius: "100%",
      border: "solid 1px #F49920",
      padding: '7px'
      // height: '85px'
    }
  })
  const CrossIcon = Styled(Icon)({
    display: 'none',
    '@media(min-width:320px) and (max-width:900px)': {
      // height: '100%',
      // width: '100px',
      display: 'block',
      position: 'absolute',
      left: '20px',
      top: '30px',
      botom: 0,
      marginTop: 'auto',
      marginBottom: 'auto',
      zIndex: 200,
      width: '40px',
      height: '40px',
      color: "#FF9A23"
      // height: '85px'
    }
  })
  const NavLinksContainer = Styled(Stack)({
    '@media(min-width:320px) and (max-width:900px)': {
      position: 'absolute',
      background: '#0D0D16',
      top: '0px',
      // width: '-100% ',
      left: '0',
      zIndex: 1200,
      height: '450px !important',
      display: 'flex',
      // display: 'none',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: '25%',
      paddingTop: '60px',
      borderRight: 'solid #FF9A23 3px',
      backdropFilter: 'blur(7px)'

    },
    '@media(min-width:450px) and (max-width:900px)': {
      width: "335px",
      paddingLeft: '13%',
    }
  })
  const ShowInMobileLogo = Styled(CardMedia)({
    display: 'none',
    '@media(min-width:320px) and (max-width:900px)': {
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      // height: '85px'
      marginTop: '23px'
    }
  })
  const Logo = Styled(CardMedia)({
    '@media(min-width:320px) and (max-width:900px)': {
      display: 'none',
    }
  })
  const LogoLink = Styled(NavLink)({
    '@media(min-width:320px) and (max-width:900px)': {
      display: 'none',
    }
  })
  const NavContainer = Styled(Container)({
    height: '80px',
    background: 'transparent',
    zIndex: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media(min-width:320px) and (max-width:900px)': {
      width: '100% !important',
      marginTop: '0px !important',
      borderRadius: '0',
      position: 'initial',
      height: '120px',
      top: '0',
      left: 0,
      right: 0,
      marginLeft: '0',
      marginRight: '0',
    },
    '@media(min-width:901px) and (max-width:1000px)': {
      width: '880px'
    },
    '@media(min-width:1001px) and (max-width:1150px)': {
      width: '980px'
    },
    '@media(min-width:1151px) and (max-width:1250px)': {
      width: '1000px'
    },

  })
  const Links = Styled(NavLink)({
    textDecoration: 'none',
    color: '#FFFFFF',
    fontWeight: '300 !important',
    fontSize: '15px',
    '@media(min-width:320px) and (max-width:900px)': {
      fontSize: '20px',
      textAlign: 'left',
      color: "#FFFFFF"
    }
  })
  const GetInTouchButton = Styled(Button)({
    border: '1px solid #F49920',
    borderRadius: '0px 180px 180px 180px',
    fontSize: '14px',
    fontWeight: '500',
    padding: '7px 30px',
    color: '#fff',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: "#F49920"
    }
    // '@media(min-width:320px) and (max-width:900px)': {}
  })
  // const changeColor = () => {
  //   if (window.scrollY >= 1) {
  //     let header = document.getElementById('header')
  //     header.classList.add("sticky")
  //   }
  //   else {
  //     let header = document.getElementById('header')

  //     header.classList.remove("sticky")
  //   }
  // }
  // window.addEventListener('scroll', changeColor)
  const barsHandler = () => {
    let bars = document.getElementById('NavLinksContainer')
    let crossIcon = document.getElementById('CrossIcon')
    let menubar = document.getElementById('menuBars')
    menubar.classList.toggle("showBars")
    crossIcon.classList.toggle("crossAnimate")
    bars.classList.toggle("showMenu")
  }
  return (
    <>
      <NavWrapper maxWidth='100%' sx={{ position: 'relative', padding: '10px 0px', backgroundColor: `rgba(13, 13, 22, 1)`, backdropFilter: 'blur(7px)', zIndex: 100000 }}>
        <NavContainer id='header'>
          <BarsIcon icon="uil:bars" onClick={barsHandler} id='menuBars' />
          <Stack sx={{ width: '100% !important', height: '100%' }} direction='row' justifyContent='center' alignItems='center'>
            <NavLinksWrapper maxWidth='lg' sx={{ height: '100% !important' }}>
              <NavLinksContainer id='NavLinksContainer' direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} justifyContent='center' alignItems='center' sx={{ padding: '7px 0px', height: '100% !important', width: '100%', }}>
                <CrossIcon id='CrossIcon' icon="fluent-emoji-high-contrast:cross-mark" onClick={barsHandler} />
                <LogoLink to='/' onClick={barsHandler}>
                  <Logo
                    component="img"
                    image="./assets/images/logo.svg"
                    alt="nav image"
                    sx={{ width: 'fit-content', height: '55px !important', marginTop: '10px', cursor: 'pointer' }}
                  />
                </LogoLink>
                <Stack sx={{ width: '70%' }}>
                  <Stack spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} justifyContent='center' alignItems='center'>
                    <Links onClick={barsHandler} id='navLink' to='/' >Home</Links>
                    <Links onClick={barsHandler} id='navLink' to='about'>About</Links>
                    <Links onClick={barsHandler} id='navLink' to='services'>Services</Links>
                    <Links onClick={barsHandler} id='navLink' to='career'>Career</Links>
                  </Stack>
                </Stack>
                <Stack mt={{ xs: 2, sm: 2, md: 0 }} ml={{ xs: 2, sm: 2, md: 0 }} sx={{ textDecoration: "none" }} component={RouterLink} to='contact' direction='row' justifyContent='center' alignItems='center'>
                  <GetInTouchButton>Get in touch</GetInTouchButton>
                </Stack>
              </NavLinksContainer>
              <ShowInMobileLogo
                component="img"
                image="./assets/images/logo.svg"
                alt="nav image"
                sx={{ width: 'fit-content', height: '70px', marginTop: '20px' }}
              />
            </NavLinksWrapper>
          </Stack>
          <MessageIcon component={RouterLink} to='contact'>
            <Stack direction='row' sx={{ height: '100%', width: '100%' }} justifyContent='center' alignItems='center'>
              <Icon icon="ant-design:message-filled" color="#f49920" width='50px' height='50px' />
            </Stack>
          </MessageIcon>
        </NavContainer>
      </NavWrapper>
      <Outlet />
      <Footer />
    </>
  )
}

export default Navbar
