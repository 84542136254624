import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Icon } from '@iconify/react';
import { Button, Stack } from '@mui/material';
import styled from 'styled-components';

const AccordionHeading = styled(Typography)({
  '@media(min-width:320px) and (max-width:540px)': {
    fontSize: '.9rem !important',
  },
});

export default function Accordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Stack spacing={2} sx={{ marginTop: '100px' }}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={{
          backgroundColor: 'rgba(13, 13, 13, 1)',
          boxShadow: 'none',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderImage: ' linear-gradient(to bottom,  rgba(255, 173, 90, 1),  rgba(185, 184, 183, 0.32) ) 4',
          color: '#FFFFFF',
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === 'panel1' ? (
              <Icon icon="ic:round-minus" color="#f49920" width="30" height="30" />
            ) : (
              <Icon icon="ic:round-plus" color="#f49920" width="30" height="30" />
            )
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <AccordionHeading variant="subtitle1">Amazon Brand Growth Strategist - Remote Global</AccordionHeading>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Typography sx={{ fontSize: '0.9rem', fontWeight: 200 }}>
              Advance AMZ is a Spree tail Agency, our mission at A-AMZ is to be premier Amazon strategists by
              successfully evolving and adapting to the ever-changing environment that is Amazon and e-commerce. Our
              strategic growth mindset and holistic approach encourage forward-thinking and provide the greatest
              potential for success for our Brand Partners.
            </Typography>
            <Typography sx={{ fontSize: '0.9rem', fontWeight: 200 }}>
              The ideal candidate can learn quickly, is a strong communicator, and has knowledge about selling on
              Amazon. Our team members are expected to learn Advance AMZ Experts’ internal processes, master the art of
              selling on Amazon, and have a great attitude. Working for a fast-paced and growing company makes this
              position an exciting opportunity!
            </Typography>
          </Stack>
          <Stack mt={3}>
            <Button fullWidth variant="contained" sx={{ fontSize: '1.2rem', color: '#FFFFFF', padding: '10px 0px' }}>
              Apply Now
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
