import React from 'react'
import { Container, Stack, Typography, CardMedia } from '@mui/material'
import Heading from './heading'
import Accordions from './accordion'

const Career = () => {
  return (
    <>
      <Container maxWidth='md' sx={{ paddingTop: "50px" }}>
        <Stack spacing={-16} direction='row' justifyContent='center' alignItems='center'>
          <CardMedia
            component='img'
            image='./assets/images/career.svg'
            sx={{ height: "200px", width: "270px", objectFit: "fill" }}
          />
          <Stack direction='column' sx={{ position: "relative", marginTop: "-60px !important" }} justifyContent='flex-start' alignItems='flex-start'>
            <Typography variant='subtitle1' sx={{ fontSize: "2rem", fontWeight: 300, lineHeight: 1.1, color: "#FFFFFF" }}>Find your<br /> next career </Typography>
            <Typography variant='subtitle1' sx={{ fontSize: "1.1rem", fontWeight: 200, color: "#FFFFFF", marginLeft: "3px" }}>opportunity at</Typography>
            <CardMedia
              component='img'
              image='./assets/images/careerSmallArrow.svg'
              sx={{ height: "50px", width: "50px", objectFit: "fill", position: "absolute", bottom: '-20px', right: "30px" }}
            />
          </Stack>
        </Stack>
      </Container>
      <Container maxWidth='lg' sx={{ marginTop: "60px", paddingBottom: "100px" }}>
        <Heading />
        <Accordions />
      </Container>
    </>
  )
}

export default Career