const PRIMARY = {
  main: '#FC9B0B',
  dark: '#FC9B0B',
}
const SECONDARY = {
  main: '#FC9B0B',
  dark: '#FC9B0B',
}


const palette = {
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
}

export default palette
