import React from 'react'
import { Typography, Stack, Container, Grid, CardMedia } from '@mui/material'
import OutLineHeading from '../outlineHeading'


const Heading = () => {
  return (
    <div style={{ background: 'rgb(13, 13, 22)', }}>
      <OutLineHeading outLined='Our' white='team' color='member' />
      <Container maxWidth='md'>
        <Grid container justifyContent='center' alignItem='center' mt={3} spacing={1}>
          <Grid item xs={12} sm={5} md={5}>
            <Stack>
              <CardMedia
                component='img'
                image='./assets/images/ceo.svg'
                sx={{ height: "100%", width: '100%', objectFit: 'fill', borderWidth: '1px', borderStyle: "solid", borderImage: " linear-gradient(to bottom,  rgba(255, 199, 144, 1),  rgba(255, 166, 77, 0) ) 4" }}
              />
              <Stack mt={1} justifyContent='center' alignItems='center'>
                <Typography variant='subtitle1' sx={{ fontWeight: 700, color: "#FFFFFF", fontSize: '1.2rem' }}>Saqlain Ali</Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: "#FFFFFF", fontSize: '1rem' }}>CEO</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <Stack>
              <CardMedia
                component='img'
                image='./assets/images/manager.svg'
                sx={{ height: "100%", width: '100%', objectFit: 'fill', borderWidth: '1px', borderStyle: "solid", borderImage: " linear-gradient(to bottom,  rgba(255, 199, 144, 1),  rgba(255, 166, 77, 0) ) 4" }}
              />
              <Stack mt={1} justifyContent='center' alignItems='center'>
                <Typography variant='subtitle1' sx={{ fontWeight: 700, color: "#FFFFFF", fontSize: '1.2rem' }}>Prokriti Polli</Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: "#FFFFFF", fontSize: '1rem' }}>BRAND MANAGER</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Heading