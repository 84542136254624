import React from 'react'
import { useRoutes } from 'react-router-dom'
import NavBar from '../layout/navBar'
import About from '../pages/about'
import Home from '../pages/home'
import Services from '../pages/services'
import Contact from '../pages/contact'
import Career from '../pages/career'
// ---------------------------------------------------------------------

const Router = () => {
  return useRoutes([
    {
      path: '/',
      element: <NavBar />,
      children: [
        { path: '/', element: <Home /> },
        { path: 'services', element: <Services /> },
        { path: 'about', element: <About /> },
        { path: 'contact', element: <Contact /> },
        { path: 'career', element: <Career /> },
      ]
    }
  ])
}
export default Router
