import React from 'react';
import { Box, CardMedia, Container, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { Icon } from '@iconify/react';

const ReviewsContainer = styled(Container)`
  background: linear-gradient(268.16deg, #f8ba35 1.25%, #654603 99.31%);
  border-radius: 0px;
  height: 60vh;
  position: relative;
  @media (min-width: 320px) and (max-width: 1200px) {
    height: fit-content;
    padding: 20px 20px !important;
  }
`;

const ReviewBox = styled(Box)({
  backgroundColor: '#343131',
  width: '460px',
  padding: '24px 40px',
  position: 'relative',
  marginTop: '-130px',
  marginLeft: '50px',
  '@media(min-width:320px) and (max-width:1200px)': {
    marginTop: '20px',
    width: '90%',
    marginLeft: '00px',
  },
});
const ReviewBox2 = styled(Box)({
  backgroundColor: '#343131',
  width: '570px',
  padding: '24px 40px',
  position: 'relative',
  marginTop: '-40px',
  marginRight: '50px',
  '@media(min-width:320px) and (max-width:1200px)': {
    marginTop: '40px',
    width: '90%',
    marginRight: '0px',
  },
});
const ReviewBox3 = styled(Box)({
  backgroundColor: '#343131',
  width: '570px',
  padding: '24px 40px',
  position: 'relative',
  marginTop: '50px',
  marginRight: '30px',
  boxShadow: '0px 24px 34px rgba(69, 69, 69, 0.35)',
  zIndex: 10,
  '@media(min-width:320px) and (max-width:1200px)': {
    marginTop: '40px',
    width: '90% !important',
    marginRight: '0px !important',
  },
});
const PreviewStartContainer = styled(Stack)({
  display: 'block',
  '@media(min-width:320px) and (max-width:1200px)': {
    display: 'none',
  },
});
const MobilePreviewStartContainer = styled(Stack)({
  display: 'none',
  '@media(min-width:320px) and (max-width:1200px)': {
    display: 'flex',
    justifyContent: 'center !important',
    alignItems: 'center !important',
  },
});
const ReviewContainer = styled('div')({
  '@media(min-width:320px) and (max-width:1200px)': {
    padding: '0px 0px !important',
  },
});
const Reviews = () => {
  return (
    <ReviewContainer style={{ backgroundColor: 'rgba(13, 13, 22, 1)', padding: '220px 0px' }}>
      <ReviewsContainer maxWidth="lg" sx={{ padding: '0px !important', position: 'relative' }}>
        <MobilePreviewStartContainer direction="column" justifyContent="center" alignItems="center">
          <Stack direction="row" sx={{ width: 'fit-content' }} justifyContent="center" alignItems="center">
            <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
            <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
            <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
            <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
            <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
          </Stack>
          <Typography sx={{ fontSize: '23px', fontWeight: '500', color: '#FFFFFF' }}>Positive Review’s</Typography>
        </MobilePreviewStartContainer>
        <Box
          sx={{
            height: '40vh',
            position: 'absolute',
            right: '-100px',
            top: '100px',
            background: 'rgba(255, 231, 115, 0.7)',
            filter: 'blur(160px)',
          }}
        ></Box>
        <Stack
          mt={0}
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <ReviewBox>
            <CardMedia
              component="img"
              image="./assets/images/reviewStar1.svg"
              alt="arrow Img"
              sx={{
                width: '30px',
                height: '30px',
                objectFit: 'fill !important',
                position: 'absolute',
                top: '-17px',
                left: '-17px',
              }}
            />
            <Stack
              spacing={3}
              direction={{ xs: 'column', sm: 'row', md: 'row' }}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <CardMedia
                component="img"
                image="./assets/images/user1.jpg"
                alt="arrow Img"
                sx={{ width: '150px !important', height: '100px', objectFit: 'fill !important' }}
              />
              <Stack direction="column">
                <Typography sx={{ fontWeight: '300', fontSize: ' 15px', color: '#FFFFFF' }}>
                  AdvanceAMZ has driven an extra $1.5 million in sales at a 18.54% ACoS over the last 2 years of working
                  with them. Partnering with them has been a great decision!
                </Typography>
              </Stack>
            </Stack>
            <Stack pt={4} direction="row" justifyContent="space-between" alignItems="center">
              <Stack>
                <Typography sx={{ fontSize: '15px', fontWeight: 500, color: '#FFC727' }}>CEO</Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    color: '#fff',
                    fontWeight: 400,
                  }}
                >
                  ProTuff
                </Typography>
              </Stack>
              {/* <Stack>
                <Typography
                  sx={{ color: '#6FDA44', fontSize: '21px', fontFamily: "'Akaya Telivigala', cursive !important" }}
                >
                  Up<span style={{ color: '#ffff', fontFamily: 'initial !important' }}>work</span>
                </Typography>
              </Stack> */}
            </Stack>
          </ReviewBox>

          <ReviewBox2>
            <CardMedia
              component="img"
              image="./assets/images/reviewStar2.svg"
              alt="arrow Img"
              sx={{
                width: '30px',
                height: '30px',
                objectFit: 'fill !important',
                position: 'absolute',
                top: '-17px',
                left: '-17px',
              }}
            />
            <Stack
              spacing={3}
              direction={{ xs: 'column', sm: 'row', md: 'row' }}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <CardMedia
                component="img"
                image="./assets/images/user2.jpg"
                alt="arrow Img"
                sx={{ width: '150px', height: '100px', objectFit: 'fill !important' }}
              />
              <Stack direction="column">
                <Typography sx={{ fontWeight: '400', fontSize: ' 15px', color: '#FFFFFF' }}>
                  Advance AMz should be your go-to source for all things Amazon Advertising. I have worked with Saqlain
                  for years and he knows her stuff. He and her team are some of the best in the business.
                </Typography>
              </Stack>
            </Stack>
            <Stack pt={4} direction="row" justifyContent="space-between" alignItems="center">
              <Stack>
                <Typography sx={{ fontSize: '15px', fontWeight: 500, color: '#FFC727' }}>CEO</Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    color: '#fff',
                    fontWeight: 400,
                  }}
                >
                  BabySeaters
                </Typography>
              </Stack>
              {/* <Stack>
                <Typography
                  sx={{ color: '#6FDA44', fontSize: '21px', fontFamily: "'Akaya Telivigala', cursive !important" }}
                >
                  Up<span style={{ color: '#ffff', fontFamily: 'initial !important' }}>work</span>
                </Typography>
              </Stack> */}
            </Stack>
          </ReviewBox2>
        </Stack>
        <Stack
          sx={{ width: '100%' }}
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          justifyContent="center"
          alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
        >
          <PreviewStartContainer direction="column" mr={10} mt={5}>
            <Stack direction="row">
              <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
              <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
              <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
              <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
              <Icon icon="material-symbols:star" color="#ffc727" width="34px" height="34px" />
            </Stack>
            <Typography sx={{ fontSize: '23px', fontWeight: '500', color: '#FFFFFF' }}>Positive Review’s</Typography>
          </PreviewStartContainer>
          <ReviewBox3>
            <CardMedia
              component="img"
              image="./assets/images/reviewStar3.svg"
              alt="arrow Img"
              sx={{
                width: '30px',
                height: '30px',
                objectFit: 'fill !important',
                position: 'absolute',
                top: '-17px',
                left: '-17px',
              }}
            />
            <Stack
              spacing={3}
              direction={{ xs: 'column', sm: 'row', md: 'row' }}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <CardMedia
                component="img"
                image="./assets/images/user3.jpg"
                alt="arrow Img"
                sx={{ width: '150px', height: '100px', objectFit: 'fill !important' }}
              />
              <Stack direction="column">
                <Typography sx={{ fontWeight: '400', fontSize: ' 15px', color: '#FFFFFF' }}>
                  We had high expectations and they haven't disappointed. Very knowledgeable, highly communicative and
                  pleasant to work with. Very pleased with a focus on strategy, execution, and results. Would highly
                  recommend.
                </Typography>
              </Stack>
            </Stack>
            <Stack pt={3} direction="row" justifyContent="space-between" alignItems="center">
              <Stack>
                <Typography sx={{ fontSize: '15px', fontWeight: 500, color: '#FFC727' }}>CEO</Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    color: '#fff',
                  }}
                >
                  Mixology
                </Typography>
              </Stack>
              {/* <Stack>
                <Typography
                  sx={{ color: '#6FDA44', fontSize: '21px', fontFamily: "'Akaya Telivigala', cursive !important" }}
                >
                  Up<span style={{ color: '#ffff', fontFamily: 'initial !important' }}>work</span>
                </Typography>
              </Stack> */}
            </Stack>
          </ReviewBox3>
          ..
        </Stack>
        <CardMedia
          component="img"
          image="./assets/images/reviewsSectionLine1.svg"
          alt="arrow Img"
          sx={{
            width: '100%',
            height: 'fit-content',
            objectFit: 'fill !important',
            position: 'absolute',
            bottom: '0px',
            left: '0',
          }}
        />
        <CardMedia
          component="img"
          image="./assets/images/reviewSectionLine2.svg"
          alt="arrow Img"
          sx={{
            width: '100%',
            height: 'fit-content',
            objectFit: 'fill !important',
            position: 'absolute',
            bottom: '0px',
            left: '0',
          }}
        />
      </ReviewsContainer>
    </ReviewContainer>
  );
};

export default Reviews;
