import React from 'react';
import { Button, CardMedia, Container, Stack, Typography } from '@mui/material';
import Styled from 'styled-components';
import './services.css';
import { Link as RouterLink } from 'react-router-dom';

const HeadingWrapper = Styled(Container)({
  margin: '0px auto',
  height: 'fit-content',
  paddingTop: '110px',
  paddingBottom: '70px',
  '@media(min-width:320px) and (max-width:900px)': {
    paddingTop: '0px',
    width: '100% !important',
  },
});

const GetInTouchButton = Styled(Button)`
  font-size: 16px;
  font-weight: 600;
  text-transform: initial;
  color: #ffff;
  letter-spacing:1px;
  &:hover{
    background-color: transparent;
  }
`;

const BannerHeading = Styled(Typography)`
color: black;
	font-size:40px;
  position: relative;
	-webkit-text-stroke: 0.3px white;
	-webkit-text-fill-color: transparent;
`;
const ArrowImage = Styled(CardMedia)({
  '@media(min-width:320px) and (max-width:500px)': {
    display: 'none',
  },
});

const Banner = () => {
  return (
    <HeadingWrapper maxWidth="md">
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Stack
          spacing={{ xs: -3, sm: -3, md: 0 }}
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            spacing={{ xs: -1.5, sm: 1, md: -1.5 }}
            direction={{ xs: 'column', sm: 'row', md: 'column' }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{
                color: '#fff',
                fontWeight: 600,
                fontSize: '80px !important',
                lineHeight: '1.2',
                width: 'fit-content',
              }}
            >
              {' '}
              HOW
            </Typography>
            <BannerHeading
              variant="h1"
              sx={{
                '@media(min-width:320px) and (max-width:900px)': {
                  fontSize: '70px !important',
                },
                fontWeight: 600,
                fontSize: '50px !important',
                color: '#FFFFFF',
                lineHeight: '1.2',
                display: 'flex',
                textAlign: 'center',
              }}
            >
              WE CAN
            </BannerHeading>
          </Stack>
          <Typography
            sx={{
              '@media(min-width:320px) and (max-width:420px)': { fontSize: '100px !important' },
              marginLeft: '16px',
              color: '#F49920',
              fontWeight: 600,
              fontSize: '150px !important',
              lineHeight: '1.2',
              width: 'fit-content',
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              HE
              <CardMedia
                component="img"
                image="./assets/images/helpingHand.svg"
                alt="arrow Img"
                sx={{
                  '@media(min-width:320px) and (max-width:420px)': { height: '100px !important' },
                  width: '130px',
                  height: '140px',
                  objectFit: 'fill !important',
                  margin: '0px -28px',
                }}
              />
              P
            </Stack>
          </Typography>
        </Stack>
        <Stack
          sx={{ padding: '0px 120px', '@media(min-width:320px) and (max-width:900px)': { padding: '20px !important' } }}
        >
          <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 400, color: '#FFFFFFCC' }}>
            An e-commerce and Amazon marketing agency that has been around for over a decade, Advance AMZ provides a
            full spectrum of services.
          </Typography>
        </Stack>
        <Stack
          mt={4}
          justifyContent="center"
          alignItems="center"
          sx={{ textDecoration: 'none' }}
          component={RouterLink}
          to="/contact"
        >
          <Stack className="box">
            <GetInTouchButton>Let’s chat about your idea</GetInTouchButton>
            <ArrowImage
              id="getIntouchButtonArrow"
              component="img"
              image="./assets/images/getInTouchButtonArrow.svg"
              alt="arrow Img"
              sx={{
                width: '20px',
                height: '20px',
                objectFit: 'fill !important',
                position: 'absolute',
                right: '50px !important',
                top: '22px',
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </HeadingWrapper>
  );
};

export default Banner;
