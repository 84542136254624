import React from 'react';
import { Box, Container } from '@mui/material';
// import styled from 'styled-components'
import AllServices from './allServices';
import OutLineHeading from '../outlineHeading';
import styled from 'styled-components';

const LightBox = styled(Box)({
  '@media(min-width:320px) and (max-width:900px)': {
    display: 'none',
  },
});

const Services = () => {
  return (
    <Container maxWidth="lg" sx={{ position: 'relative' }}>
      <LightBox
        sx={{
          height: '60vh',
          position: 'absolute',
          left: '-100px',
          top: '200px',
          background: 'rgba(255, 231, 115, 1)',
          filter: 'blur(160px)',
        }}
      >
        sdfsdf
      </LightBox>
      <OutLineHeading outLined="Our" color="Services" />
      <div>
        <AllServices
          title="Listing"
          title2="Optimization"
          para1="Ecommerce Is Competitive, And Only The Best Listings Win The Sale."
          para2="Amazon listing optimization is the cornerstone to selling success, improving everything from search rankings to traffic to conversions. But it takes more than just good writing and keyword research — the best Amazon listing optimization services understand the AMAZON RANKING ALGORITHM."
          img="./assets/images/listing.svg"
          highLight="&"
        />
        <div style={{ position: 'relative' }}>
          <LightBox
            sx={{
              height: '30vh',
              position: 'absolute',
              right: '-230px',
              top: '-200px',
              background: 'rgba(255, 231, 115, 01)',
              filter: 'blur(120px)',
            }}
          >
            sdfsdf
          </LightBox>
        </div>
        <AllServices
          direction="row-reverse"
          title="Amazon"
          title2="PPC"
          para1="The average seller spends approximately $1200 per month on PPC, but can considerably reduce costs through effective management. While PPC campaigns are easy to setup, optimization may prove to be tricky."
          para2="Our Amazon PPC Experts work intensively to help you manage PPC advertising campaigns and lower your costs."
          img="./assets/images/amazonPpc.svg"
        />
        <div style={{ position: 'relative' }}>
          <LightBox
            sx={{
              height: '60vh',
              position: 'absolute',
              left: '-200px',
              top: '30px',
              background: 'rgba(255, 231, 115, 1)',
              filter: 'blur(160px)',
            }}
          >
            sdfsdf
          </LightBox>
        </div>
        <AllServices
          title="A+"
          title2="Content"
          para1="Enhanced Brand Content can literally help you stand out in the toughest niches by giving your customers a window into who you are and what your product is all about."
          para2="At Advance AMZ, we have the right talent to help you with your listings. Enhance your listings with our Amazon Enhanced Brand Content Service."
          img="./assets/images/content.svg"
          firstHighLight={true}
          marginTop={true}
        />
        <div style={{ position: 'relative' }}>
          <LightBox
            sx={{
              height: '40vh',
              position: 'absolute',
              right: '-150px',
              top: '100px',
              background: 'rgba(255, 231, 115, 1)',
              filter: 'blur(160px)',
            }}
          >
            sdfsdf
          </LightBox>
        </div>
        <AllServices
          direction="row-reverse"
          title="Issue"
          title2="Management"
          para1="We having years of experience and know the in and outs of the Amazon system. "
          para2="We know sometimes it can be frustrating and even overwhelming to deal with some of the issues that suddenly happen to us sellers, but don't worry we are here to help you fix those issues & get all set up again."
          img="./assets/images/withOutName.svg"
        />
        <div style={{ position: 'relative' }}>
          <LightBox
            sx={{
              height: '40vh',
              position: 'absolute',
              left: '-150px',
              top: '100px',
              background: 'rgba(255, 231, 115, 1)',
              filter: 'blur(160px)',
            }}
          >
            sdfsdf
          </LightBox>
        </div>
        <AllServices
          title="Account"
          title2="Management"
          para1="Advance AMZ account management while you focus on your business. We help sellers save their time and money by managing and optimising their amazon account whether it's Listing Optimization, PPC Sponsored Ads, Issue management, A+ content creation, etc."
          para2="We don't only focus on lowering ACos, but also focus on Your Brand Building and organic growth."
          img="./assets/images/management.svg"
        />
      </div>
    </Container>
  );
};

export default Services;
