import React from 'react'
import { GlobalStyles as GlobalThemeStyles } from '@mui/material'

export default function GlobalStyles() {
  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          fontFamily: "'Poppins', 'sans-serif !important'"
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          overFlow: "hidden"
        },
        body: {
          width: '100%',
          height: '100%',
          overflowX: 'hidden',
          overFlow: "hidden",
          backgroundColor: '#0D0D16 !important',
        },
        '#root': {
          width: '100%',
          height: '100%'
        },
        input: {
          outline: "none",
          border: "none"
        },
        button: {
          border: 'none',
        },
        img: { display: 'block', maxWidth: '100%' },
        textShadow: 'none !important'
        // '*::-webkit-scrollbar': {
        //   width: '0.3em',
        // },
        // '*::-webkit-scrollbar-track': {
        //   '-webkit-box-shadow': 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;',
        //   backgroundColor: "#fff"
        // },
        // '*::-webkit-scrollbar-thumb': {
        //   background: '#000',
        //   borderRadius: "100px",
        //   // borderTopRadius: "10px",
        //   height: '6rem',
        //   '-webkit-box-shadow': 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;'
        // },
      }}


    />
  )
}
