import React from 'react'
import OutLineHeading from '../outlineHeading'
import Slider from './slider'
import AOS from 'aos';
import 'aos/dist/aos.css';

const index = () => {
  AOS.init({
    once: false
  });
  return (
    <div style={{ backgroundColor: '#0D0D16', paddingTop: '60px' }}>
      <div
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-duration="800"><OutLineHeading outLined='BRANDS' white='we work' color='with' /></div>
      <Slider />
    </div>
  )
}

export default index