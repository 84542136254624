import React from 'react'
import { Typography, Stack } from '@mui/material'
import Styled from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css';

const BannerHeading = Styled(Typography)`
  color: black;
  position: relative;
	-webkit-text-stroke: 1px #FFFFFF;
  -webkit-text-stroke-width: 0.4px;
	-webkit-text-fill-color: transparent !important;
  opacity: 0.3;
  @media(min-width:320px) and (max-width:650px){
   font-size: 70px !important
  }
`
const Advertising = Styled(Typography)({
  fontWeight: 600,
  fontSize: '3rem !important',
  lineHeight: '1.2',
  position: "relative",
  zIndex: 1,
  '@media(min-width:320px) and (max-width:340px)': {
    fontSize: '1.5rem !important'
  },
  '@media(min-width:341px) and (max-width:650px)': {
    fontSize: '2rem !important'
  }
})

const BlurHeading = Styled(Stack)`
position:relative;
&:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(13, 13, 22);
  filter: blur(8px);
  z-index:0;
};
@media(min-width:320px) and (max-width:650px){
  padding:5px 50px
 }

`

const OutLineHeading = ({ outLined, white, color }) => {
  AOS.init({
    once: false
  });
  return (
    <div style={{ background: 'rgb(13, 13, 22)' }}>
      <Stack data-aos="fade-up"
        data-aos-offset="0"
        data-aos-duration="800" spacing={{ xs: -4.5, sm: -4.5, md: -6 }} direction='column' justifyContent='center' alignItems='center'>
        <BannerHeading variant='h1' sx={{ textTransform: 'uppercase', fontWeight: 600, fontSize: '106px !important', lineHeight: '1.2', display: 'flex', textAlign: 'center' }}>
          {outLined}
        </BannerHeading>
        <BlurHeading direction='row' sx={{ zIndex: 100, padding: '5px 120px' }}>
          <Advertising sx={{ zIndex: 10, color: '#FFFFFF !important', }}> {white} <span style={{ color: '#F49920' }}>{color}</span></Advertising>
        </BlurHeading>
      </Stack>
    </div>
  )
}

export default OutLineHeading