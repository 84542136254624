import React, { useRef, useState } from 'react'
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Box, Button, Container, Grid, Stack, TextField, Typography } from '@mui/material'
import './contact.css'
import emailjs from '@emailjs/browser'
import SubmitMsg from '../submitMsg'
// ***************************************

const ContactForm = () => {
  const [showMsg, setShowMsg] = useState(false)
  const [customerName, setCustomerName] = useState('')
  const form = useRef();
  const subscribeSchema = Yup.object().shape({
    firstName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
    lastName: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
    brandName: Yup.string().required('Brand name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.number().positive().integer().typeError('Only number are allowed').required('Phone number is required'),
    monthlySale: Yup.number().positive().integer().typeError('Only number are allowed').required('Monthly sale is required'),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      brandName: '',
      phoneNumber: '',
      monthlySale: '',

      message: '',
    },
    validationSchema: subscribeSchema,
    onSubmit: async () => {
      const params = {
        firstName: values.firstName,
        lastName: values.lastName
      }
      setCustomerName(params)
      emailjs.sendForm(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        })
      setTimeout(function () {
        setShowMsg(true)
        setTimeout(function () {
          setShowMsg(false)
        }, 7000);
      }, 1000)
      resetForm()
      console.log(values)
    }
  })
  const {
    handleSubmit, getFieldProps, values, resetForm
  } = formik
  const textFieldStyle = {
    fontWeight: 600,
    fontSize: "1.1rem",
    color: "rgba(244, 153, 32, 1)"
  }
  return (
    <Container>
      <Grid container justifyContent='center' alignItems='center' mt={12}>
        <Grid item xs={12} sm={12} md={9} lg={7} sx={{ backgroundColor: "rgba(13, 13, 13, 1)", borderWidth: '1px', borderStyle: "solid", borderImage: " linear-gradient(to bottom,  rgba(255, 199, 144, 1),  rgba(255, 166, 77, 0) ) 4" }}>
          <FormikProvider value={formik}>
            <Form
              id='form'
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
              style={{ width: "100%" }}
              ref={form}
            >
              <Box p={4}>
                <Stack spacing={1} mb={2}>
                  <Typography sx={textFieldStyle}>Name<span style={{ color: "rgba(255, 0, 0, 1)" }}>*</span> </Typography>
                  <Stack spacing={{ xs: 2, sm: 2, md: 5 }} direction={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent='space-between' alignItems='center'>
                    <Box sx={{ position: 'relative', width: "100%" }}>
                      <TextField
                        id='textField'
                        type='text'
                        fullWidth
                        name='firstName'
                        {...getFieldProps('firstName')}
                        variant='outlined'
                        placeholder='First Name'
                      />
                      <ErrorMessage name="firstName">
                        {msg => <div style={{ color: '#FE6B6B', marginTop: "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </Box>
                    <Box sx={{ position: 'relative', width: "100%" }}>
                      <TextField
                        id='textField'
                        type='text'
                        fullWidth
                        name='lastName'
                        {...getFieldProps('lastName')}
                        variant='outlined'
                        placeholder='Last Name'
                      />
                      <ErrorMessage name="lastName">
                        {msg => <div style={{ color: '#FE6B6B', marginTop: "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </Box>
                  </Stack>
                </Stack>
                <Stack mb={2} spacing={{ xs: 2, sm: 2, md: 5 }} direction={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent='space-between' alignItems='center'>
                  <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography sx={textFieldStyle}>Brand Name<span style={{ color: "rgba(255, 0, 0, 1)" }}>*</span> </Typography>
                    <Box sx={{ width: "100%" }}>
                      <TextField
                        id='textField'
                        type='text'
                        fullWidth
                        name='brandName'
                        {...getFieldProps('brandName')}
                        variant='outlined'
                        placeholder='Brand Name'
                      />
                      <ErrorMessage name="brandName">
                        {msg => <div style={{ color: '#FE6B6B', marginTop: "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </Box>
                  </Stack>
                  <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography sx={textFieldStyle}>Monthly Sale<span style={{ color: "rgba(255, 0, 0, 1)" }}>*</span> </Typography>
                    <Box sx={{ position: 'relative', width: "100%" }}>
                      <TextField
                        id='textField'
                        type='number'
                        fullWidth
                        name='monthlySale'
                        {...getFieldProps('monthlySale')}
                        variant='outlined'
                        placeholder='Monthly Sale'
                      />
                      <ErrorMessage name="monthlySale">
                        {msg => <div style={{ color: '#FE6B6B', marginTop: "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </Box>
                  </Stack>
                </Stack>
                <Stack mb={2} spacing={{ xs: 2, sm: 2, md: 5 }} direction={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent='space-between' alignItems='center'>
                  <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography sx={textFieldStyle}>Email<span style={{ color: "rgba(255, 0, 0, 1)" }}>*</span> </Typography>
                    <Box sx={{ position: 'relative', width: "100%" }}>
                      <TextField
                        id='textField'
                        type='text'
                        fullWidth
                        name='email'
                        {...getFieldProps('email')}
                        variant='outlined'
                        placeholder='Email'
                      />
                      <ErrorMessage name="email">
                        {msg => <div style={{ color: '#FE6B6B', marginTop: "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </Box>
                  </Stack>
                  <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography sx={textFieldStyle}>Phone<span style={{ color: "rgba(255, 0, 0, 1)" }}>*</span> </Typography>
                    <Box sx={{ position: 'relative', width: "100%" }}>
                      <TextField
                        id='textField'
                        type='number'
                        fullWidth
                        name='phoneNumber'
                        {...getFieldProps('phoneNumber')}
                        variant='outlined'
                        placeholder='Phone Name'
                      />
                      <ErrorMessage name="phoneNumber">
                        {msg => <div style={{ color: '#FE6B6B', marginTop: "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </Box>
                  </Stack>
                </Stack>
                <Stack spacing={1} mt={2}>
                  <Typography sx={textFieldStyle}>Message</Typography>
                  <TextField
                    id='message'
                    type='text'
                    fullWidth
                    name='message'
                    {...getFieldProps('message')}
                    placeholder='A thought you have'
                    multiline
                    rows={4}
                  />
                </Stack>
              </Box>
              <Stack mb={-5} sx={{ width: "100%" }} direction='row' justifyContent='center' alignItems='center' spacing={2}>
                <Button fullWidth type='submit' variant='contained' color='secondary' sx={{ padding: "5px 40px", fontSize: '1.7rem', fontWeight: 600, color: "#FFFFFF" }}>Submit</Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Grid>
      </Grid>
      {showMsg && <SubmitMsg {...{ customerName }} />}
    </Container>
  )
}

export default ContactForm