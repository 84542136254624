import React from 'react'
import Page from '../components/helmet'
import Banner from '../components/services/banner'
import AllServices from '../components/services/services'

const Services = () => {
  return (
    <Page title='Advance | AMZ | Services'>
      <Banner />
      <div style={{ backgroundColor: '#0D0D16', paddingTop: '60px' }}>
        <AllServices />
      </div>
    </Page>
  )
}

export default Services