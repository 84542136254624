import React, { useEffect, useRef } from 'react'
import './banner.css'

const OnOffButton = () => {
  let knob = useRef()
  useEffect(() => {
    setTimeout(function () {
      knob.current.classList.add("mystyle")
    }, 500);
  }, [])
  return (
    <div>
      <input type="checkbox" id="lol-checkbox" />
      <label id="button" ref={knob} for="lol-checkbox">
        <div id="knob"></div>
        <img id='amazonImg' src="./assets/images/springAmazon.svg" alt="amazonImg" />
        <div id="alright">ON</div>
      </label>
    </div>

  )
}

export default OnOffButton