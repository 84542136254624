import React from 'react';
import { Container, Typography, Grid, CardMedia, Stack } from '@mui/material';
import Styled from 'styled-components';
const BannerHeading = Styled(Typography)`
color: black;
	font-size:40px;
  position: relative;
	-webkit-text-stroke: 0.3px white;
	-webkit-text-fill-color: transparent;
`;
const Para = Styled(Typography)({
  '@media(min-width:320px) and (max-width:900px)': {
    textAlign: 'center',
    padding: '0px 10px',
  },
  '@media(min-width:550px) and (max-width:900px)': {
    padding: '0px 100px',
  },
});

const WhatWeDo = () => {
  return (
    <Container maxWidth="lg" sx={{ padding: '100px 0px !important' }}>
      <Grid container spacing={8}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          sx={{
            position: 'relative',
            '@media(min-width:900px) and (max-width:1250px)': { paddingLeft: '100px !important' },
          }}
        >
          <Stack
            justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start' }}
            alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
          >
            <BannerHeading
              variant="h1"
              sx={{
                fontWeight: 600,
                fontSize: '50px !important',
                color: '#FFFFFF',
                lineHeight: '1.2',
                display: 'flex',
                textAlign: 'center',
              }}
            >
              WHAT
            </BannerHeading>
            <Typography
              sx={{
                marginTop: '-2px',
                color: '#FFFFFF',
                fontWeight: 600,
                fontSize: '50px !important',
                lineHeight: '1.2',
                width: 'fit-content',
              }}
            >
              Do <span style={{ margin: '0px -7px', color: '#F49920' }}>We</span> <span>Do</span>
              <br />
              <p style={{ marginTop: '-6px ', fontWeight: 700, fontSize: '60px' }}>Exactly</p>
            </Typography>
            <Stack sx={{ width: '100%' }} mt={10} justifyContent="center" alignItems="center">
              <Para sx={{ lineHeight: 1.6, fontSize: '18px', fontWeight: 300, color: '#FFFFFF' }}>
                Unleash your digital potential through data and high performance digital marketing. Get a free, no
                obligation quote.
              </Para>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <CardMedia
              component="img"
              image="./assets/images/listing.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: '270px', objectFit: 'fill !important' }}
            />
            <Typography sx={{ textAlign: 'center', color: '#ffff', fontWeight: '500', fontSize: '20px' }}>
              Listing &<br />
              Optimization
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <CardMedia
              component="img"
              image="./assets/images/amazonPpc.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: '270px', objectFit: 'fill !important' }}
            />
            <Typography sx={{ textAlign: 'center', color: '#ffff', fontWeight: '500', fontSize: '20px' }}>
              {' '}
              Amazon
              <br />
              PPC
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <CardMedia
              component="img"
              image="./assets/images/content.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: '270px', objectFit: 'fill !important' }}
            />
            <Typography sx={{ textAlign: 'center', color: '#ffff', fontWeight: '500', fontSize: '20px' }}>
              {' '}
              A+
              <br />
              Content
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <CardMedia
              component="img"
              image="./assets/images/withOutName.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: '270px', objectFit: 'fill !important' }}
            />
            <Typography sx={{ textAlign: 'center', color: '#ffff', fontWeight: '500', fontSize: '20px' }}>
              {' '}
              Amazon
              <br />
              PPC
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <CardMedia
              component="img"
              image="./assets/images/management.svg"
              alt="arrow Img"
              sx={{ width: '100%', height: '270px', objectFit: 'fill !important' }}
            />
            <Typography sx={{ textAlign: 'center', color: '#ffff', fontWeight: '500', fontSize: '20px' }}>
              {' '}
              Account
              <br />
              Management
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhatWeDo;
