import React from 'react'
import Heading from '../components/banner/heading'
import Page from '../components/helmet'
import Reviews from '../components/reviews/reviews'
import WhatWeDo from '../components/whatWeDo/whatWeDo'
import Slider from '../components/slider'
import styled from 'styled-components'


const SliderContainer = styled('div')({
  '@media(min-width:320px) and (max-width:900px)': {
    paddingTop: '0 !important'
  }
})

const Home = () => {
  return (
    <Page title='Advance | AMZ | Home'>
      <Heading />
      <SliderContainer style={{ backgroundColor: '#0D0D16', paddingTop: '60px' }}>
        <Slider />
        <WhatWeDo />
      </SliderContainer>
      <Reviews />
    </Page>
  )
}

export default Home
