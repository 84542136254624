import React from 'react';
import { Typography, Stack, Container, CardMedia } from '@mui/material';
import Styled from 'styled-components';

const GradiantNumber = Styled(Typography)`

background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #C4C4C4 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

`;

const BgStyle = Styled.div`
background: rgb(13, 13, 22);
height: 90vh;
position: relative;
@media(min-width:320px) and (max-width:900px) {
  height: fit-content;
  margin-bottom: 100px
}
`;
const HeadingWrapper = Styled(Container)({
  margin: '0px auto',
  height: 'fit-content',
  paddingTop: '90px',
  '@media(min-width:320px) and (max-width:900px)': {
    paddingTop: '0px',
    width: '100% !important',
    margin: '40px 0px !important',
  },
  '@media(min-width:320px) and (max-width:535px)': {
    textAlign: 'center',
  },
});
const ParaContainer = Styled(Stack)({
  '@media(min-width:320px) and (max-width:535px)': {
    padding: 0,
  },
});
const Banner = () => {
  return (
    <BgStyle>
      <HeadingWrapper maxWidth="lg" sx={{ height: '100%', position: 'relative' }}>
        <Stack spacing={-1} justifyContent="center" alignItems="center">
          <Typography variant="subtitle1" sx={{ color: '#FFFFFF', fontSize: '1.4rem' }}>
            How it All Began and at
          </Typography>
          <Typography variant="h2" sx={{ color: '#FFFFFF', fontSize: '2.5rem', fontWeight: 700 }}>
            <span style={{ color: '#F49920' }}>What Level </span>we are today
          </Typography>
        </Stack>
      </HeadingWrapper>
      <Container maxWidth="md">
        <ParaContainer
          sx={{ textAlign: 'center', padding: '0px 60px' }}
          justifyContent="center"
          alignItems="center"
          mt={3}
        >
          <Typography variant="body1" sx={{ color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 200 }}>
            We brought together the right specialists and the right associates to provide a 360º digital experience and
            help you start, renew, or extend your business online. With years of experience combined within the agency,
            we guarantee the same level of quality, and expertise, behind each service we provide.
          </Typography>
        </ParaContainer>
        <Stack
          spacing={{ xs: 4, sm: 4, md: 0 }}
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          mt={8}
          sx={{ padding: '0px 40px', height: 'fit-content' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack>
            <GradiantNumber variant="h1" sx={{ color: '#FFFFFF', fontSize: '4.4rem', fontWeight: 700 }}>
              2015
            </GradiantNumber>
            <Typography
              variant="subtitle1"
              sx={{ color: 'rgba(244, 153, 32, 0.8)', fontSize: '1.2rem', fontWeight: 300, textAlign: 'center' }}
            >
              Since we here
            </Typography>
          </Stack>
          <Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={-1}>
              <GradiantNumber variant="h1" sx={{ color: '#FFFFFF', fontSize: '4.4rem', fontWeight: 700 }}>
                5000
              </GradiantNumber>
              <CardMedia
                component="img"
                image="./assets/images/plus.svg"
                sx={{ height: '40px', width: '50px', objectFit: 'fill' }}
              />
            </Stack>
            <Typography
              variant="subtitle1"
              sx={{ color: 'rgba(244, 153, 32, 0.8)', fontSize: '1.2rem', fontWeight: 300, textAlign: 'center' }}
            >
              Product Listing
            </Typography>
          </Stack>
          <Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={-1}>
              <GradiantNumber variant="h1" sx={{ color: '#FFFFFF', fontSize: '4.4rem', fontWeight: 700 }}>
                50
              </GradiantNumber>
              <CardMedia
                component="img"
                image="./assets/images/plus.svg"
                sx={{ height: '40px', width: '50px', objectFit: 'fill' }}
              />
            </Stack>
            <Typography
              variant="subtitle1"
              sx={{ color: 'rgba(244, 153, 32, 0.8)', fontSize: '1.2rem', fontWeight: 300, textAlign: 'center' }}
            >
              Since we here
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </BgStyle>
  );
};

export default Banner;
