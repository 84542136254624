import React, { useRef, useEffect } from 'react'
import { Typography, Stack, Container, CardMedia, Button } from '@mui/material'
import Styled from 'styled-components'
import OnOffButton from './onOffButton'
import { NavLink as RouterLink } from 'react-router-dom'
import './banner.css'


const BgStyle = Styled.div`
background: rgba(13, 13, 22, 1);
height: 80vh;
position: relative,
z-index:-100;
@media(min-width:320px) and (max-width:520px){
  height: 46vh;
}
`
const HeadingWrapper = Styled(Container)({
  margin: "0px auto",
  height: "fit-content",
  paddingTop: '110px',
  '@media(min-width:320px) and (max-width:900px)': {
    paddingTop: '0px',
    width: '100% !important',
    margin: '40px 0px !important'

  }
})
const GetInTouchButton = Styled(Button)`
font-size: 16px;
font-weight: 600;
text-transform: initial;
color: #ffff;
letter-spacing:1px;
&:hover{
  background-color: transparent;
}
`
const BannerHeading = Styled(Typography)`
  color: black;
	font-size:30px;
  position: relative;
	-webkit-text-stroke: 0.3px white;
	-webkit-text-fill-color: transparent;
  @media(min-width:320px) and (max-width:900px){
    display:none
  }
`
const MobileHeading = Styled(Typography)`
  color: black;
	font-size:40px;
  position: relative;
	-webkit-text-stroke: 0.3px white;
	-webkit-text-fill-color: transparent;
`
const HeadingContainer = Styled(Stack)({
  display: 'block',
  '@media(min-width:320px) and (max-width:900px)': {
    display: 'none'
  }
})
const MobileHeadingContainer = Styled(Stack)({
  display: 'none',
  '@media(min-width:320px) and (max-width:900px)': {
    display: 'block'
  }
})
const Advertising = Styled(Typography)`
color: white;
	font-size:40px;
	-webkit-text-stroke: 0.5px white;
	-webkit-text-fill-color: white;
`
const BannerArrowImg = Styled(CardMedia)({
  '@media(min-width:320px) and (max-width:400px)': {
    // position: 'absolute',
    // left: '0px',
    // top: '-30px'
    display: 'none'
  },
  '@media(min-width:401px) and (max-width:900px)': {
    position: 'absolute',
    left: '0px',
    top: '20px'
  }
})
const AmazonHeading = Styled(Typography)({
  color: '#F49920',
  fontSize: '80px',
  fontWeight: ' 600',
  textTransform: 'uppercase',
  '@media(min-width:320px) and (max-width:600px)': {
    fontSize: "60px !important",
    marginTop: "-10px !important"
  },
  '@media(min-width:601px) and (max-width:900px)': {
    fontSize: "80px !important",
    marginTop: "-10px !important"
  }
})
const BottomArrow = Styled(CardMedia)({
  '@media(min-width:320px) and (max-width:900px)': {
    // position: 'absolute',
    // right: '100px',
    // bottom: '-130px'
    display: 'none'
  }
})
const SalesImg = Styled(CardMedia)({
  '@media(min-width:320px) and (max-width:400px)': {
    position: 'absolute',
    right: '-24px !important',
    top: "-15px"
  }
})
const Heading = () => {
  const graphLine = useRef()
  useEffect(() => {
    setTimeout(function () {
      graphLine.current.classList.add("active")
    }, 100);
  }, [])
  return (
    <BgStyle >
      <HeadingWrapper maxWidth='lg' sx={{ height: '100%', position: 'relative' }}>
        <BannerArrowImg
          component="img"
          image="./assets/images/bannerArrow.svg"
          alt="arrow Img"
          sx={{ width: '50px', height: '50px', objectFit: 'fill !important', position: 'absolute', left: '90px', top: '70px' }}
        />
        <HeadingContainer>
          <Stack sx={{ zIndex: -1 }} direction='row' justifyContent='center' alignItems='center'>
            <BannerHeading variant='h1' sx={{ fontWeight: 600, fontSize: '40px !important', color: '#FFFFFF', lineHeight: '1.2', display: 'flex', textAlign: 'center' }}>
              Increase Your  <Advertising sx={{ margin: '0px 10px', fontWeight: 600, fontSize: '40px !important', lineHeight: '1.2', width: 'fit-content' }}> Advertising</Advertising>Sales
              <CardMedia
                component="img"
                image="./assets/images/bannerSalesImg.svg"
                alt="arrow Img"
                sx={{ width: '40px', height: '40px', objectFit: 'fill !important', position: 'absolute', right: '-30px', top: '-12px' }}
              />
            </BannerHeading>
          </Stack>
        </HeadingContainer>

        <MobileHeadingContainer>
          <Stack sx={{ zIndex: -1 }} direction='row' justifyContent='center' alignItems='center'>
            <Stack direction='column' justifyContent='center' alignItems='center'>
              <MobileHeading variant='h1' sx={{ fontWeight: 600, fontSize: '40px !important', color: '#FFFFFF', lineHeight: '1.2', display: 'flex', textAlign: 'center' }}>
                Increase Your
              </MobileHeading>
              <MobileHeading>
                <Stack mt={-1} direction='row' justifyContent='center' alignItems='center'>
                  <Advertising sx={{ margin: '0px 10px', fontWeight: 600, fontSize: '30px !important', lineHeight: '1.2', width: 'fit-content' }}> Advertising</Advertising>
                  Sales
                </Stack>
                <SalesImg
                  component="img"
                  image="./assets/images/bannerSalesImg.svg"
                  alt="arrow Img"
                  sx={{ width: '40px', height: '40px', objectFit: 'fill !important', position: 'absolute', right: '-35px', top: '-12px' }}
                />
              </MobileHeading>
            </Stack>
          </Stack>
        </MobileHeadingContainer>

        <Stack mt={-1} spacing={1} direction={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent='center' alignItems='center' >
          <OnOffButton />
          <AmazonHeading>
            Amazon
          </AmazonHeading>
        </Stack>
        <Stack mt={1} justifyContent='center' alignItems='center'>
          <Stack className='box' sx={{ textDecoration: "none", zIndex: 1000, position: "relative" }} component={RouterLink} to='contact'>
            <GetInTouchButton > Get in touch</GetInTouchButton>
            <CardMedia
              id='getIntouchButtonArrow'
              component="img"
              image="./assets/images/getInTouchButtonArrow.svg"
              alt="arrow Img"
              sx={{ width: '20px', height: '20px', objectFit: 'fill !important', position: 'absolute', right: '40px !important', top: '20px' }}
            />
          </Stack>
        </Stack>
        <BottomArrow
          component="img"
          image="./assets/images/bannerTwoArrow.svg"
          alt="arrow Img"
          sx={{ width: '100px', height: '100px', objectFit: 'fill !important', position: 'absolute', right: '100px', bottom: '-230px' }}
        />
        <svg ref={graphLine} id='graphLine' style={{ position: "absolute", top: '250px' }} viewBox="0 0 1440 423" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-7 352L421.5 412L735 295L999 346L1447.5 3" stroke="url(#paint0_linear_708_10)" stroke-width="6" class="svg-elem-1"></path>
          <circle cx="423" cy="411" r="11.5" fill="#F49920" class="svg-elem-2"></circle>
          <circle cx="423" cy="411" r="8.5" fill="white" class="svg-elem-3"></circle>
          <circle cx="735" cy="296" r="11.5" fill="#F49920" class="svg-elem-4"></circle>
          <circle cx="735" cy="296" r="8.5" fill="white" class="svg-elem-5"></circle>
          <circle cx="1000" cy="345" r="11.5" fill="#F49920" class="svg-elem-6"></circle>
          <circle cx="1000" cy="345" r="8.5" fill="white" class="svg-elem-7"></circle>
          <defs>
            <linearGradient id="paint0_linear_708_10" x1="1447.5" y1="-55.9997" x2="108" y2="396" gradientUnits="userSpaceOnUse">
              <stop stop-color="#CF7600"></stop>
              <stop offset="0.477083" stop-color="#FFCC88" stop-opacity="0.522917"></stop>
              <stop offset="1" stop-color="white" stop-opacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>


      </HeadingWrapper>
    </BgStyle>
  )
}

export default Heading