import React from 'react'
import Banner from '../components/about/banner'
import Page from '../components/helmet'
import Team from '../components/about/team'

const About = () => {
  return (
    <Page title='Advance | AMZ | About'>
      <Banner />
      <Team />
    </Page>
  )
}

export default About