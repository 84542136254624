import React from 'react';
import { Button, CardMedia, Link, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const ButtonStyle1 = styled(Button)({
  padding: '10px 50px !important',
  color: '#ffff',
  fontSize: '1.2rem',
  borderRadius: '0px !important',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: '#FC9B0B',
  },
});
const ButtonStyle2 = styled(Button)({
  padding: '10px 30px !important',
  fontSize: '1.2rem',
  color: '#ffff',
  borderRadius: '0px !important',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: '#383838',
  },
});
const FooterPara = styled(Typography)({
  '@media(min-width:320px) and (max-width:1200px)': {
    padding: '0px 20px !important',
  },
});
const FooterContainer = styled(Typography)({
  '@media(min-width:320px) and (max-width:900px)': {
    padding: '70px 0px',
  },
});
const FooterArrow = styled(CardMedia)({
  '@media(min-width:320px) and (max-width:900px)': {
    display: 'none',
  },
});
const Footer = () => {
  return (
    <FooterContainer
      maxWidth="100%"
      sx={{ backgroundColor: '#0D0D16', color: '#fff', padding: '100px 0px', position: 'relative' }}
    >
      <Stack spacing={4} justifyContent="center" alignItems="center">
        <Typography sx={{ fontSize: '50px', fontWeight: 700 }}>Let’s Talk</Typography>
        <FooterPara sx={{ fontSize: '20px', fontWeight: 200, textAlign: 'center', padding: '0px 400px' }}>
          Unleash your digital potential through data and high performance digital marketing. Get a free, no obligation
          quote.
        </FooterPara>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center">
          <ButtonStyle1 component={RouterLink} to="contact" style={{ backgroundColor: '#FC9B0B' }}>
            Let’s Talk
          </ButtonStyle1>
          <ButtonStyle2 type="number" component={Link} href="tel:+03135596055" style={{ backgroundColor: '#383838' }}>
            Book a Call
          </ButtonStyle2>
        </Stack>
      </Stack>
      <FooterArrow
        component="img"
        image="./assets/images/footerImg_A.svg"
        sx={{ height: '200px', width: '300px', objectFit: 'fill', position: 'absolute', bottom: '0px', right: '-60px' }}
      />
    </FooterContainer>
  );
};

export default Footer;
