import React from 'react'
import Banner from '../components/contact/banner'
import Form from '../components/contact/form'
import Page from '../components/helmet'
const Contact = () => {
  return (
    <Page title='Advance | AMZ | Contact'>
      <div style={{ background: 'rgba(13, 13, 22, 1)', paddingBottom: "100px", minHeight: "100vh" }}>
        <Banner />
        <Form />
      </div>
    </Page>
  )
}

export default Contact