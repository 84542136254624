import React from 'react'
import { CardMedia, Grid, Stack, Typography } from '@mui/material'
import styled from 'styled-components'

const AllServices = ({ title, para1, para2, img, highLight, title2, direction, firstHighLight, marginTop }) => {
  const paraStyle = {
    fontSize: '16px',
    fontWeight: 300,
    color: '#FFFFFF'
  }
  const style = {
    ...(marginTop) ? { marginTop: '-70px' } : { marginTop: 0 }
  }
  const GridContainer = styled(Grid)({
    '@media(min-width:320px) and (max-width:901px)': {
      display: "none"
    }
  })
  const MobileGridContainer = styled(Grid)({
    '@media(min-width:900px)': {
      display: "none"
    }
  })
  return (
    <>
      <GridContainer container spacing={12} sx={{ marginTop: '0px' }} direction={direction || 'row'}>
        <Grid item xs={12} sm={6} md={6}>
          <Stack sx={style}>
            <Typography variant='h6' sx={{ lineHeight: 1.2, color: '#FFFFFF', fontSize: '60px', fontWeight: 600 }}>
              {!firstHighLight && title} {!highLight && <br />}
              {firstHighLight && <span style={{ color: '#F49920' }}>{title}</span>}
              {!firstHighLight && <span style={{ color: '#F49920' }}>{highLight || title2}</span>}<br />
              {highLight && title2}
              {firstHighLight && title2}
            </Typography>
            <Stack mt={4} spacing={4}>
              <Typography sx={paraStyle}>
                {para1}
              </Typography>
              <Typography sx={paraStyle}>
                {para2}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CardMedia
            component="img"
            image={img}
            alt="arrow Img"
            sx={{ width: '100%', height: '90%', objectFit: 'fill !important' }}
          />
        </Grid>
      </GridContainer>



      <MobileGridContainer container spacing={12} sx={{ marginTop: '0px' }} direction={direction || 'row'}>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={style}>
            <Typography variant='h6' sx={{ lineHeight: 1.2, color: '#FFFFFF', fontSize: '40px', fontWeight: 600 }}>
              {!firstHighLight && title} {!highLight && <br />}
              {firstHighLight && <span style={{ color: '#F49920' }}>{title}</span>}
              {!firstHighLight && <span style={{ color: '#F49920' }}>{highLight || title2}</span>}<br />
              {highLight && title2}
              {firstHighLight && title2}
            </Typography>
            <CardMedia
              component="img"
              image={img}
              alt="arrow Img"
              sx={{ '@media(min-width:500px) and (max-width:900px)': { width: '400px !important' }, width: '100%', height: '90%', objectFit: 'fill !important', marginTop: "40px" }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{paddingTop:'20px !important'}}>
          <Stack spacing={4}>
            <Typography sx={paraStyle}>
              {para1}
            </Typography>
            <Typography sx={paraStyle}>
              {para2}
            </Typography>
          </Stack>
        </Grid>
      </MobileGridContainer>
    </>
  )
}

export default AllServices