import React from 'react'
import Page from '../components/helmet'
import CareerComponent from '../components/career/career'

const Career = () => {
  return (
    <Page title='Advance | AMZ | Career'>
      <div style={{ backgroundColor: "#0D0D16", minHeight: "100vh" }}>
        <CareerComponent />
      </div>
    </Page>
  )
}

export default Career