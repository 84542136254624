import React from 'react'
import { Typography, Stack } from '@mui/material'
import Styled from 'styled-components'

const BannerHeading = Styled(Typography)`
  color: black;
  position: relative;
	-webkit-text-stroke: 1px #FFFFFF;
  -webkit-text-stroke-width: 0.4px;
	-webkit-text-fill-color: transparent !important;
  opacity: 0.3;
  @media(min-width:320px) and (max-width:650px){
    font-size: 70px !important
   }
`
const Advertising = Styled(Typography)({
  fontWeight: 600,
  fontSize: '3rem !important',
  lineHeight: '1.2',
  position: "relative",
  zIndex: 1,
  '@media(min-width:320px) and (max-width:340px)': {
    fontSize: '1.5rem !important'
  },
  '@media(min-width:341px) and (max-width:650px)': {
    fontSize: '2rem !important'
  }
})

const BlurHeading = Styled(Stack)`
position:relative;
padding:5px 150px;
&:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0D0D16;
  filter: blur(8px);
  z-index:0;
};
@media(min-width:320px) and (max-width:650px){
  padding:5px 150px
 }

`

const Heading = () => {
  return (
    <div style={{ background: '#0D0D16' }}>
      <Stack spacing={{ xs: -4.5, sm: -6.2, md: -6.2 }} direction='column' justifyContent='center' alignItems='center'>
        <BannerHeading variant='h1' sx={{ textTransform: 'uppercase', fontWeight: 600, fontSize: '106px !important', lineHeight: '1.2', display: 'flex', textAlign: 'center' }}>
          CURRENT
        </BannerHeading>
        <BlurHeading direction='row' sx={{ zIndex: 100, padding: '5px 120px' }}>
          <Advertising sx={{ zIndex: 10, color: '#FFFFFF !important', }}><span style={{ color: '#F49920' }}>OPENINGS</span></Advertising>
        </BlurHeading>
      </Stack>
      <Stack justifyContent='center' alignItems='center' mt={1}>
        <Typography sx={{ fontSize: "1.3rem", color: "#FFFFFF", fontWeight: 300 }}>We heartily welcome talented and <br /> enthusiastic people to join our team.</Typography>
      </Stack>
    </div>
  )
}

export default Heading