// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled }
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: 'rgba(255, 255, 255, 0.4)',
            fontSize: "0.9rem"
          },
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: "none"
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: "#fff !important",
            // border: 'solid 1px #89181C'
            border: "1px solid rgba(255, 255, 255, 0.3)"
          },
          '&.Mui-focused': {
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            backgroundColor: "transparent"
          }
        },
        underline: {
          '&:before': {
            borderBottom: "none"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover fieldset': {
            border: 'solid 1px rgba(255, 255, 255, 0.3) !important'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'solid 1px rgba(255, 255, 255, 0.3) !important'
          },
        }
      }
    }
  }
}
